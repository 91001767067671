import React from "react";
import { createRoot } from "react-dom/client";
import HomepageHero from "../../../../components/vof/hero/HomepageHero";

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('homepage-hero');
  if (!el) {
    console.warn('React mount point #homepage-hero not found');
    return;
  }

  try {
    const props = JSON.parse(el.dataset.props || '{}');
    const root = createRoot(el);
    root.render(<HomepageHero {...props} />);
  } catch (e) {
    console.error('Error mounting HomepageHero:', e);
  }
});
